import React, { useState } from 'react';
import './CardPromoDetailed.css';
import { Button, Modal, Level } from "react-bulma-components";
import ArrowDown from "../../images/openBlack.svg"

function CardPromoDetailed(props) {
    const [activeSections, setActiveSections] = useState({
        tariffName: false,
        tariffInclude: false,
        tariffAdvantages: false,
        stages: Array(7).fill(false),
    });

    const toggleSection = (section) => {
        setActiveSections(prevState => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    const toggleStage = (index) => {
        setActiveSections(prevState => ({
            ...prevState,
            stages: prevState.stages.map((isActive, i) => i === index ? !isActive : isActive),
        }));
    };

    const handleClose = () => {
        props.setShowDetails(false);
    };

    const stages = [
        "Выбор идеи для бизнеса",
        "Проработка и детализация идеи",
        "Анализ потребительских предпочтений и проведение интервью",
        "Прототипирование и тестирование продукта",
        "Разработка финансовой модели и бизнес-плана",
        "Создание маркетингового плана",
        "Поиск финансирования и регистрация бизнеса",
    ];

    const renderSection = (title, content, sectionKey) => (
        <div className='card-promo__modal_body_block' onClick={() => toggleSection(sectionKey)}>
            <Level breakpoint='mobile'>
                <Level.Side align='left'>
                    <p className='card-promo__tariff_title'>{title}</p>
                </Level.Side>
                <Level.Side align='right' className='card-promo__tariff_title_icon'>
                    <img src={ArrowDown} className={activeSections[sectionKey] && 'dropdown-up'} alt="Arrow" />
                </Level.Side>
            </Level>
            {activeSections[sectionKey] && content}
        </div>
    );

    const renderStage = (stage, index) => (
        <div className='card-promo__modal_stages_block' onClick={() => toggleStage(index)} key={index}>
            <Level>
                <Level.Side className='card-promo__modal_stages_title' align='left' display='block'>
                    <span style={{ color: '#8259F7' }}>Этап {index + 1}: </span>
                    <span>{stage}</span>
                    <img src={ArrowDown} className='card-promo__modal_stages_arrow' alt="Arrow" />
                </Level.Side>
                {/* <Level.Side align='right'>
                    <label className="checkbox">
                        <input type="checkbox" />
                    </label>
                </Level.Side> */}
            </Level>
            {activeSections.stages[index] && 
                <div style={{ fontSize: '14px' }}>
                    Чтобы идея стала реальностью, воспользуйся нашими сервисами с ИИ.
                </div>
            }
        </div>
    );

    return (
        <Modal show={props.isShowDetails} onClose={handleClose} closeOnBlur={true}> 
            <div className='modal-background' onClick={handleClose}></div>
            <Modal.Card className='modal_width'>
                <Modal.Card.Body className='card-promo__modal_body'>
                    {renderSection(
                        `Тариф "${props.info.title}"`,
                        <p>{props.info.firstInfoBlock}</p>,
                        'tariffName'
                    )}
                    {renderSection(
                        `Что входит в тариф "${props.info.title}"`,
                        <ul>
                            <li>{props.info.tariffContains1}</li>
                            <li>{props.info.tariffContains2}</li>
                            <li>{props.info.tariffContains3}</li>
                            {props.info.id !== 2 && <li>{props.info.tariffContains4}</li>}
                        </ul>,
                        'tariffInclude'
                    )}
                    {renderSection(
                        `Преимущества тарифа "${props.info.title}"`,
                        <>
                            <ul>
                                <li>{props.info.tariffAdvantages1}</li>
                                <li>{props.info.tariffAdvantages2}</li>
                                <li>{props.info.tariffAdvantages3}</li>
                            </ul>
                            <div>{props.info.tariffAdvantages4}</div>
                        </>,
                        'tariffAdvantages'
                    )}
                    {props.info.id !== 1 && (
                        <div className='card-promo__modal_body_block'>
                            <p className='card-promo__tariff_title' style={{ marginBottom: '20px' }}>Выберите этап</p>
                            {stages.map(renderStage)}
                        </div>
                    )}
                    <div className='card-promo__modal_body_block'>
                        <Level>
                            <Level.Side align='left' display='block'>
                                <p className='card-promo__tariff_title'>{props.info.price}</p>
                            </Level.Side>
                            <Level.Side align='right'>
                                <Button className='card-promo__tariff_button_buy'>
                                    Купить
                                </Button>
                            </Level.Side>
                        </Level>
                    </div>
                </Modal.Card.Body>
            </Modal.Card>
        </Modal>
    );
}

export default CardPromoDetailed;