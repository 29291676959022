import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import './About.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import lamp from "../../images/lamp.png";
import sheet from "../../images/sheet.png";
import laptop from "../../images/laptop.png";
import bag from "../../images/bag.png";

import techs1 from "../../images/techs1.svg"
import techs2 from "../../images/techs2.svg"
import techs3 from "../../images/techs3.svg"

function About() {
  const navigate = useNavigate();

  function SimpleSlider() {
    const settings = {
      dots: true,
      infinite: true,
      arrows: true,
      speed: 800,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        // {
        //   breakpoint: 768,
        //   settings: {
        //     slidesToShow: 2,
        //     slidesToScroll: 1,
        //   }
        // },
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ],
    };
    return (
      <div className="slider-container">
        <Slider {...settings}>
          <div>
            <li className="about__promo-item" onClick={() => navigate('/promo', { replace: true })}>
              <img className="about__promo-image" src={lamp} alt="Лампочка"/>
              <p className="about__promo-title">Найдем идею для бизнеса, которая подходит именно тебе</p>
            </li>
          </div>
          <div>
            <li className="about__promo-item" onClick={() => navigate('/promo', { replace: true })}>
              <img className="about__promo-image" src={sheet} alt="Письмо"/>
              <p className="about__promo-title">Подробно расскажем, что и как делать, чтобы идея стала бизнесом</p>
            </li>
          </div>
          <div>
            <li className="about__promo-item" onClick={() => navigate('/promo', { replace: true })}>
              <img className="about__promo-image" src={laptop} alt="Ноутбук"/>
              <p className="about__promo-title">Чат бот на основе нашей ИИ–модели ответит на любой вопрос о бизнесе</p>
            </li>
          </div>
          <div>
            <li className="about__promo-item" onClick={() => navigate('/promo', { replace: true })}>
              <img className="about__promo-image" src={bag} alt="Портфель"/>
              <p className="about__promo-title">Эксперты помогут реализовать каждый этап подготовки к бизнесу</p>
            </li>
          </div>
        </Slider>
      </div>
    );
  }
  return (
    <section className="about">
      <div className="about__promo">
        <p className="about__title">Что мы предлагаем</p>
        <div className="is-hidden-tablet">{SimpleSlider()}</div>
        <ul className="about__promo-list is-hidden-mobile">
          <li className="about__promo-item" onClick={() => navigate('/promo', { replace: true })}>
            <img className="about__promo-image" src={lamp} alt="Лампочка"/>
            <p className="about__promo-title">Найдем идею для бизнеса, которая подходит именно тебе</p>
          </li>
          <li className="about__promo-item" onClick={() => navigate('/promo', { replace: true })}>
            <img className="about__promo-image" src={sheet} alt="Письмо"/>
            <p className="about__promo-title">Подробно расскажем, что и как делать, чтобы идея стала бизнесом</p>
          </li>
          <li className="about__promo-item" onClick={() => navigate('/promo', { replace: true })}>
            <img className="about__promo-image" src={laptop} alt="Ноутбук"/>
            <p className="about__promo-title">Чат бот на основе нашей ИИ–модели ответит на любой вопрос о бизнесе</p>
          </li>
          <li className="about__promo-item" onClick={() => navigate('/promo', { replace: true })}>
            <img className="about__promo-image" src={bag} alt="Портфель"/>
            <p className="about__promo-title">Эксперты помогут реализовать каждый этап подготовки к бизнесу</p>
          </li>
        </ul>
        <div className="about__buy">
          <p className="about__buy-info">С помощью наших экспертов и ИИ-модели мы поможем тебе создать работающую бизнес идею и воплотить ее в жизнь</p>
          <Link to="/promo" className="about__button" type="button">Купить</Link>
        </div>
      </div>
    </section>
  )
}

export default About;