import openIconWhite from "../images/Vector733.svg";
import openIconBlack from "../images/openBlack.svg";
import Ai from "../images/frameAi.png";

export const SCREEN_SIZE = {
  DESKTOP: 1280,
  TABLET: 768,
}

export const TECHS_INFO = {
  titleFst: "Интеграция Искусственного Интеллекта",
  titleSnd: "Комплексный подход к бизнес-консалтингу",
  titleTrd: `Персонализированный подход к каждому клиенту`,
  subtitleFst: "Мы&nbsp;используем передовые технологии&nbsp;ИИ для анализа данных и&nbsp;автоматизации процессов, что позволяет экономить дорогостоящее время лучших консультантов и&nbsp;предлагать вам доступные сервисы",
  subtitleSnd: "Наш сервис включает всё необходимое от базы идей и стартовых шаблонов документов до детальной валидации бизнес-моделей и стратегий с помощью опытных консультантов. Мы предлагаем четыре уровня тарифов, каждый из которых разработан с учетом различных стадий развития бизнеса и потребностей предпринимателя",
  subtitleTrd: "Мы не просто предоставляем общие рекомендации; мы стремимся понять уникальные вызовы и возможности каждого клиента, чтобы предложить максимально целенаправленные и эффективные решения",
}

export const PROFILE_INFO = {
  firstName: 'Имя',
  placeholderFirstName: 'Иван',
  lastName: 'Фамилия',
  placeholderLastName: 'Иванов',
  email: 'Почта',
  placeholderEmail: 'example@google.com',
  phoneNumber: 'Телефон',
  placeholderPhoneNumber: '+7 __ __ - _ - _',
  toTariffs: 'К тарифам',
  transactions: 'Транзакции:',
  profileData: 'Данные',
  services: 'Услуги',
  logOut: 'Выйти'
};

export const HEADER_INFO = {
  login: 'Войти',
  register: 'Зарегистрироваться',
  checkYourIdea: 'Проверить свою идею',
  evaluateOtherIdeas: 'Оценить чужие идеи',
  email: 'Почта',
  placeholderEmail: 'example@google.com',
  phoneNumber: 'Телефон',
  placeholderPhoneNumber: '+7 __ __ - _ - _',
  toTariffs: 'К тарифам',
  transactions: 'Транзакции:',
  profileData: 'Данные',
  services: 'Услуги'
};

export const COMMON_DATA = {
  saving: 'Сохранение...',
  saveChanges: 'Сохранить изменения',
  emptyData: 'Тут пока пусто'
};

export const SERVICE_DATA = {
  successfulUpdateData: 'Данные успешно обновлены',
  error: 'Ошибка'
};

export const promoData = [
  {
    id: 1,
    title: "Старт",
    subtitle: "Заложите основу вашего успеха",
    textAbout1st: "Полный стартовый набор",
    textAbout1stInfo: "Получите все необходимые шаблоны для начала бизнеса, включая бизнес-план и финансовые модели",
    textAbout2nd: "Интеллектуальное руководство",
    textAbout2stInfo: "Воспользуйтесь нашим ИИ для анализа и улучшения вашего плана на каждом шагу",
    textAbout3rd: "",
    template: "Шаблоны",
    stage: "",
    textEnd: "Эффективный старт за минимальные вложения. Начните сейчас и сделайте первый шаг к своему бизнесу!",
    price: "999",
    image: Ai,
    openIconBlack: openIconWhite,
    openIconWhite: openIconBlack,
    sale: false,
    aboutPromo: true,
    colorBlack: false
  },
  {
    id: 2,
    title: "Развитие",
    subtitle: "Уверенность на каждом шагу",
    textAbout1st: "Расширенные возможности",
    textAbout1stInfo: `Все преимущества тарифа "Старт" плюс индивидуальный подход к вашим потребностям`,
    textAbout2nd: "Персональная валидация",
    textAbout2stInfo: "Наши эксперты при помощи искусственного интеллекта помогут реализовать ваш проект на любом из его этапов",
    textAbout3rd: "",
    template: "",
    stage: "Этапы",
    textEnd: "Преодолейте страх и сделайте следующий шаг к успеху с `Развитием`!",
    price: "9499",
    image: Ai,
    openIconWhite: openIconWhite,
    openIconBlack: openIconBlack,
    sale: true,
    aboutPromo: false,
    colorBlack: true
  },
  {
    id: 3,
    title: "Профессионал",
    subtitle: "Персональный подход к вашему успеху",
    textAbout1st: `Все преимущества тарифов "Старт" и "Развитие" плюс эксклюзивное общение с ведущими экспертами в вашей отрасли`,
    textAbout1stInfo: "",
    textAbout2nd: "Личные консультации и наставничество с возможностью глубокого анализа и корректировки вашего бизнес-плана",
    textAbout2stInfo: "",
    textAbout3rd: "Возможность стратегического партнерства, включая поддержку в принятии ключевых решений",
    template: "",
    stages: "",
    textEnd: "",
    price: "79999",
    image: false,
    openIconBlack: false,
    openIconWhite: false,
    sale: false,
    aboutPromo: false,
    colorBlack: false
  },
];

export const stageList = [
  { title: "Выбор идеи для бизнеса"},
  { title: "Проработка и детализация идеи"},
  { title: "Анализ потребительских предпочтений и проведение интервью"},
  { title: "Прототипирование и тестирование продукта"},
  { title: "Разработка финансовой модели и бизнес-плана"},
  { title: "Создание маркетингового плана"},
  { title: "Поиск финансирования и регистрация бизнеса"}
]

export const templateList = [
  { title: "Canvas Business Model",
    subtitle: "Лучший инструмент для того чтобы начать свое дело, понять кокунрентные преимущество, увидеть слабые стороны и детализировать идею"
  },
  { title: "Финансовая модель",
    subtitle: "Шаблоны финансовой модели для разных видов бизнеса"
  },
  { title: "Бюджет маркетинговой кампании",
    subtitle: "Этот шаблон поможет вам структурировать расходы на маркетинг и рекламу"
  },
  { title: "Вопросы для проблемного интервью",
    subtitle: "Шаблон поможет подготовить вопросы и сделать интервью эффективным"
  },
  { title: "Вопросы и сценарии для Customer Development (Cust Dev)",
    subtitle: "Этот шаблон содержит вопросы для проведения интервью с потенциальными клиентами."
  }
]

const STATUS_CODE_400 = 400;
const STATUS_CODE_401 = 401;
const STATUS_CODE_409 = 409;
const STATUS_CODE_404 = 404;

const ERROR_MESSAGE = {
  USER_NOT_EXIST: "Пользователь с таким e-mail не найден",
  BAD_REQUEST_PROFILE: "При обновлении профиля произошла ошибка",
  CONFLICT_USER_DATA: "Пользователь с таким email уже существует.",
  NOT_FOUND_MOVIES: "Ничего не найдено",
  EMPTY_TEXT_INPUT: "Введите название фильма",
  USER_AUTH: "При авторизации произошла ошибка.",
  USER_CREDENTIALS: "Вы ввели неправильный логин или пароль",
};

const SUCCESS_MESSAGE = "Данные успешно обновлены";

export {
  STATUS_CODE_400,
  STATUS_CODE_401,
  STATUS_CODE_409,
  STATUS_CODE_404,
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
}

export const promoAboutData = [
  {
    id: 1,
    title: "Старт",
    subtitle: "Ваш первый шаг к успешному бизнесу",
    firstInfoBlock: "Начать свой бизнес может быть сложно, но с тарифом \"Старт\" вы получите все необходимые инструменты и ресурсы для уверенного начала. Мы предоставляем вам не только теоретические знания, но и практические инструменты, разработанные нашими опытными консультантами из большой четверки, которые сами активно применяют эти подходы в своей работе.",
    tariffContains1: "- Бизнес-план: Полный набор документов, включая шаблоны и примеры, которые помогут вам легко составить профессиональный бизнес-план.",
    tariffContains2: "- Дорожная карта: Шаг за шагом следуйте проверенному плану, который направит ваш бизнес к успеху.",
    tariffContains3: "- Финансовая модель: Понимайте и управляйте своими финансами с помощью наших настраиваемых шаблонов.",
    tariffContains4: "- Интерактивное взаимодействие с ИИ: Получите доступ к нашему интеллектуальному помощнику, который будет сопровождать вас на каждом этапе и предлагать оптимизированные решения и советы.",
    tariffAdvantages1: "- Экономия времени и ресурсов: Быстро запустите свой бизнес с помощью готовых решений.",
    tariffAdvantages2: "- Основа для масштабирования: Постройте крепкий фундамент, на котором можно безопасно развивать бизнес.",
    tariffAdvantages3: "- Поддержка от экспертов: Наши консультанты всегда готовы помочь и ответить на ваши вопросы.",
    tariffAdvantages4: "Выберите тариф \"Старт\" и сделайте первый шаг к реализации своей бизнес-идеи уже сегодня! Наши инструменты и поддержка помогут минимизировать риски и ускорить ваш путь к успеху.",
    price: "999₽"
  },
  {
    id: 2,
    title: "Развитие",
    subtitle: "Углубленный анализ и профессиональная валидация",
    firstInfoBlock: "Тариф \"Развитие\" идеально подходит для предпринимателей, которые уже выбрали / кому мало тариф(а) \"Старт\" и теперь ищут дополнительную проверку и уверенность в своих решениях от опытных консультантов. Этот тариф предлагает валидацию вашего бизнес-плана и ключевых стратегий, чтобы вы могли чувствовать себя более уверенно, продвигаясь вперед.",
    tariffContains1: "- Всё из тарифа \"Старт\": Вы получите все начальные инструменты и ресурсы.",
    tariffContains2: "- Экспертная валидация отраслевыми специалистами: Наши консультанты проверят выводы ИИ, анализируя ваш бизнес-план и предложения на предмет соответствия реальным условиям рынка.",
    tariffContains3: "- Возможность выбора проблемных этапов для валидации: Определите ключевые области, которые требуют дополнительного анализа и подтверждения.",
    tariffContains4: "",
    tariffAdvantages1: "- Подтверждение решений: Удостоверьтесь в верности вашего плана благодаря валидации опытными профессионалами.",
    tariffAdvantages2: "- Уменьшение рисков: Сократите возможные риски, связанные с начальным этапом запуска бизнеса, благодаря квалифицированной экспертизе.",
    tariffAdvantages3: "- Оптимизация стратегии: Полезные рекомендации помогут вам уточнить и улучшить вашу бизнес-стратегию.",
    tariffAdvantages4: "Выберите тариф \"Развитие\", если вы ищете не просто поддержку ИИ, но и профессиональное подтверждение ваших бизнес-решений. Мы поможем вам внести необходимые коррективы и усилить уверенность в вашем пути к успеху.",
    price: "9499₽"
  },
  {
    id: 3,
    title: "Профессионал",
    subtitle: "Максимальная поддержка и индивидуальный подход",
    firstInfoBlock: "Тариф \"Профессионал\" — это наивысший уровень поддержки, предназначенный для тех, кто стремится не только к успешному запуску, но и к долгосрочному развитию своего бизнеса. Этот пакет включает всестороннюю поддержку наших наиболее опытных консультантов, которые работают с вами один на один, чтобы обеспечить реализацию ваших бизнес-целей.",
    tariffContains1: "- Все из тарифов \"Старт\" и \"Развитие\": Включая начальные инструменты, ресурсы и экспертную валидацию.",
    tariffContains2: "- Личные консультации с топ-экспертами: Непосредственное общение и индивидуальное наставничество от лидеров отрасли.",
    tariffContains3: "- Глубокий стратегический анализ: Мы помогаем разработать и реализовать стратегии, которые соответствуют вашим долгосрочным бизнес-целям.",
    tariffContains4: "- Регулярное сопровождение и поддержка: Непрерывная поддержка на каждом этапе вашего бизнеса, включая анализ результатов и корректировку стратегий.",
    tariffAdvantages1: "- Полная адаптация под ваши нужды: Мы тщательно изучаем вашу ситуацию и предлагаем решения, полностью соответствующие вашим бизнес-задачам.",
    tariffAdvantages2: "- Преодоление сложных вызовов: Наши эксперты готовы помочь вам справиться с самыми сложными проблемами и достичь значимого прогресса.",
    tariffAdvantages3: "- Ускорение роста и повышение конкурентоспособности: С помощью нашего сопровождения ваш бизнес сможет быстрее достичь поставленных целей и улучшить свое положение на рынке.",
    tariffAdvantages4: "Выбирая тариф \"Профессионал\", вы инвестируете в свое будущее, гарантируя себе доступ к знаниям и опыту лучших в отрасли консультантов. Наша цель — ваше максимальное удовлетворение и высочайшие бизнес-результаты.",
    price: "79999"
  },
]

export const cardIdeaData = [
  {
    title: "Социальная сеть для молодых людей от 12 до 25 лет, которые хотят делать больше, чем просто делиться селфи и сплетнями. Он направлен на то, чтобы помочь молодым людям найти работу, получить доступ к возможностям и общаться со своими сверстниками",
    likes: 39,
    dislikes: 7,
    chance: 76
  },
  {
    title: "Компания, которая помогает владельцам малого бизнеса строить более стратегический маркетинговый план, позволяя им планировать свои бюджеты и планировать свои маркетинговые усилия",
    likes: 78,
    dislikes: 12,
    chance: 69
  },
  {
    title: "Социальная сеть для бездомных», которая помогает найти кров, еду и работу",
    likes: 24,
    dislikes: 9,
    chance: 56
  },
  {
    title: "Стартап, который находит творческие вакансии для творческих людей и помогает им найти работу",
    likes: 34,
    dislikes: 2,
    chance: 67
  },
  {
    title: "Это услуга, которая позволяет сделать небольшой ремонт собственного автомобиля в домашних условиях",
    likes: 23,
    dislikes: 4,
    chance: 85
  },
  {
    title: "Стартап, который помогает розничным магазинам и брендам управлять и оптимизировать свои веб-сайты, помогая им получать больше трафика и продаж",
    likes: 31,
    dislikes: 10,
    chance: 93
  },
  {
    title: "Чат-бот, который помогает вам управлять своими деньгами, задавая вам вопросы, а затем предлагая предложения о том, что делать с вашими деньгами",
    likes: 39,
    dislikes: 2,
    chance: 89
  },
  {
    title: "Стартап, который разрабатывает способ выращивания продуктов питания в почве без пестицидов или других химикатов",
    likes: 29,
    dislikes: 1,
    chance: 65
  },
  {
    title: "Платформа для покупки, продажи, обмена и аренды велосипедов. API компании предоставляет доступ к данным о велосипедах, магазинах и ремонтах, и в настоящее время у нее 30 000 списков велосипедов",
    likes: 19,
    dislikes: 5,
    chance: 56
  },
  {
    title: "Приложение для микробюджетов для людей, которые хотят заранее спланировать большие расходы (например, свадьбу)",
    likes: 25,
    dislikes: 3,
    chance: 87
  },
  {
    title: "Платформа, которая помогает людям одалживать друг другу деньги по ставке около 1% годовых",
    likes: 18,
    dislikes: 0,
    chance: 42
  },
  {
    title: "Место, где вы можете найти работу, которую можно выполнять из дома",
    likes: 24,
    dislikes: 3,
    chance: 43
  },
  {
    title: "Мобильное приложение, помогающее бросить курить",
    likes: 54,
    dislikes: 10,
    chance: 76
  },
  {
    title: "Социальная сеть для людей, которые любят поесть. Пользователи могут просматривать меню, находить рестораны, которые соответствуют их бюджету, и общаться с другими любителями еды",
    likes: 39,
    dislikes: 2,
    chance: 27
  },
  {
    title: "Стартап создает приложение, которое связывает автовладельцев с механиками, которые могут чинить сломанные автомобили",
    likes: 54,
    dislikes: 11,
    chance: 48
  },
  {
    title: "Компания, которая помогает вам найти способ зарабатывать деньги, делая то, что вы уже делаете",
    likes: 44,
    dislikes: 12,
    chance: 67
  },
  {
    title: "Приложение для обмена сообщениями, чтобы помочь людям связаться с местными органами власти. Он призван сделать правительство более доступным и прозрачным для общественности",
    likes: 40,
    dislikes: 6,
    chance: 31
  },
  {
    title: "Компания, которая продает технологии, помогающие пожилым людям сохранять свою независимость",
    likes: 26,
    dislikes: 8,
    chance: 35
  },
  {
    title: "Стартап, который помогает студентам и другим молодым специалистам найти людей своего возраста, которые хотят жить вместе",
    likes: 18,
    dislikes: 3,
    chance: 83
  },
  {
    title: "Чат-бот на основе искусственного интеллекта, который поможет вам в достижении ваших целей в фитнесе. Бот предлагает индивидуальные тренировки на основе ваших целей, задач и истории",
    likes: 38,
    dislikes: 4,
    chance: 78
  },
  {
    title: "Инструмент для повышения уровня удержания сотрудников за счет улучшения коммуникации и вовлеченности",
    likes: 14,
    dislikes: 0,
    chance: 91
  },
  {
    title: "Стартап и мобильное приложение для продажи билетов на мероприятия, которые можно вернуть, если шоу отменят, или перепродать, если нет достаточного спроса",
    likes: 12,
    dislikes: 1,
    chance: 23
  },
  {
    title: "Облачная система бухгалтерского учета для стартапов с такими функциями, как напоминания об оплате счетов, совместная работа команды и отчеты о продажах",
    likes: 23,
    dislikes: 9,
    chance: 54
  },
  {
    title: "Цифровая торговая площадка, где учащиеся могут продавать свои услуги еще во время учебы",
    likes: 20,
    dislikes: 1,
    chance: 32
  },
  {
    title: "Стартап, который создает инструмент для соискателей, чтобы узнать, насколько хорошо они справляются со своей работой",
    likes: 37,
    dislikes: 6,
    chance: 52
  },
  {
    title: "Стартап, который помогает компаниям с логистикой, следя за тем, чтобы поступающие товары были на складе, и помогая менеджерам отслеживать, что находится в процессе",
    likes: 23,
    dislikes: 15,
    chance: 79
  },
  {
    title: "Компания-разработчик программного обеспечения, которая работает с городами над размещением приложений и созданием программного обеспечения, которое может помочь городским властям в таких вещах, как управление штрафами за парковку, предоставление коммунальных услуг людям с ограниченными возможностями и отслеживание нарушений правил дорожного движения и парковки",
    likes: 12,
    dislikes: 4,
    chance: 67
  },
]