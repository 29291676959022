import React from "react";
import { Columns, Column, Button } from "react-bulma-components";

import "../../components/Subscription/Subscription.css";

function Subscription() {
    return (
        <Columns className='subscription__promocard' mx="0">
            <Columns.Column py="0" display="flex" flexDirection="column" justifyContent="space-between">
                <div className='subscription__promocard__title'>Подписка</div>
                <ul className='subscription__promocard__text'>
                    <li>- Доступ к базе идей для бизнеса</li>
                    <li>- Доступ к обученной экспертами модели ChatGPT</li>
                </ul>
            </Columns.Column>
            <Columns.Column pb="0" pt="6" size={4} display="flex" flexDirection="column" justifyContent="end">
                <div>
                    <span className='subscription__promocard__title'>399₽</span>
                    <span>/мес</span>
                </div>
                <Button className='subscription__promocard-button' fullwidth={true}>Купить</Button>
            </Columns.Column>
        </Columns>
    )
}

export default Subscription;