import React from "react";
import './Techs.css';

import techs1 from "../../images/techs1.svg"
import techs2 from "../../images/techs2.svg"
import techs3 from "../../images/techs3.svg"

import { TECHS_INFO } from "../../utils/constants";

function Techs() {
  return(
    <section className="techs">
      <ul className="techs__list">
        <li className="techs__item">
          <img className="techs__image" src={techs1} alt="Интеграция"></img>
          <h2 className="techs__title">{TECHS_INFO.titleFst}</h2>
          <p className="techs__description">{TECHS_INFO.subtitleFst}</p>
        </li>
        <li className="techs__item">
          <img className="techs__image" src={techs2} alt="Комплексный подход"></img>
          <h2 className="techs__title">{TECHS_INFO.titleSnd}</h2>
          <p className="techs__description">{TECHS_INFO.subtitleSnd}</p>
        </li>
        <li className="techs__item">
          <img className="techs__image" src={techs3} alt="Персонализированный подход"></img>
          <h2 className="techs__title">{TECHS_INFO.titleTrd}</h2>
          <p className="techs__description">{TECHS_INFO.subtitleTrd}</p>
        </li>
      </ul>
    </section>
  )
}

export default Techs;