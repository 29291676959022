import './Documents.css';
import { Section } from "react-bulma-components";

const files = [
    { name: 'Оферта ИИ бизнес-консалтинг', url: '/docs/Оферта-ИИ-бизнес-консалтинг.pdf' },
    { name: 'Согласие рассылка бизнес_консалтинг', url: '/docs/Согласие_рассылка_ИИ_бизнес_консалтинг.pdf' },
    { name: 'Согласие ПД бизнес_консалтинг', url: '/docs/Согласие_ПД_ИИ_бизнес_консалтинг.pdf' },
    { name: 'Политика обработки персональных данных', url: '/docs/Политика_обработки_персональных_данных_ИИ_бизнес_консалтинг.pdf' }
]
const handleOpenFile = (fileUrl) => {
    window.open(fileUrl, '_blank');
};

function Documents() {
    return (
        <Section mb="2" className='documents_section'>
            <div style={{ display: 'grid' }}>
                <strong>Юридическая информация</strong>
                <br/>
                <oli>
                    {files.map((file, index) => (
                        <li>
                            <a key={index} onClick={() => handleOpenFile(file.url)}>
                                {file.name}
                            </a>
                        </li>
                    ))}
                </oli>
            </div>
        </Section>
    )
}

export default Documents;