import React, { useState, useEffect }  from 'react'
import "./AuthForm.css";

import { useFormWithValidation } from '../../../hook/useForm';
import * as authApi from "../../../utils/AuthApi";
import Loader from '../../Loader/Loader';
import HideEye from "../../../images/hide-eye.svg"

function AuthForm(props) {
  const [isErrorMessage, setIsErrorMessage] = useState("");
  const [userStatus, setUserStatus] = useState(props.userStatus);
  const [verificationCode, setVerificationCode] = useState("");
  const [resendTimer, setResendTimer] = useState(0);
  const [canResend, setCanResend] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordRepeatVisible, setPasswordRepeatVisible] = useState(false);
  const [passwordMismatchError, setPasswordMismatchError] = useState("");


  const handlePasswordVisibilityToggle = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handlePasswordRepeatVisibilityToggle = () => {
    setPasswordRepeatVisible(!passwordRepeatVisible);
  };

  useEffect(() => {
    setResendTimer(120)
    setUserStatus(props.userStatus)
  }, [props.userStatus]);

  useEffect(() => {
    let interval;
    if (resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else if (resendTimer === 0 && !canResend) {
      setCanResend(true);
    }
    return () => clearInterval(interval);
  }, [resendTimer, canResend]);

  const { values, handleChange, errors, isFormValid } = useFormWithValidation();

  function onLogin(evt) {
    evt.preventDefault();
    props.onLogin(values.email, values.password);
    props.onClose()
  }

  function handleCloseModal() {
    props.closeModal(false)
    setVerificationCode("")
    setIsErrorMessage("")
    setPasswordVisible(false);
    setPasswordRepeatVisible(false);
    setPasswordMismatchError("");
    values.email = ''
    values.password = ''
    values.passwordRepeat = ''
    errors.email = ''
    errors.password = ''
  }

  const handleSubmitVerificationCode = (evt) => {
    evt.preventDefault();
    setisLoading(true)
    authApi.checkVerificationCode(values.email, verificationCode)
      .then((res) => {
        if (res.data.verified && values.password) {
          onLogin(evt);
        } 
        else if (res.data.verified) {
          handleCloseModal()
        }
        else {setIsErrorMessage("Неверный код подтверждения")}
      })
      .catch((err) => {
        console.error("Произошла ошибка при проверке кода подтверждения");
      })
      .finally(() => setisLoading(false));
  };

  const handleResendCode = () => {
    setCanResend(false);
    setResendTimer(120);
    authApi.resendVerificationCode(values.email)
      .then((res) => {
        if (res.success) {
          setIsErrorMessage("");
        } else {
          setIsErrorMessage("Ошибка при отправке кода");
        }
      })
      .catch((err) => {
        console.error(`Ошибка: ${err}`);
        setIsErrorMessage("Произошла ошибка при отправке кода подтверждения");
      });
  };

  const handleContinueClick = (evt) => {
    evt.preventDefault()
    props.handleContinueClick(values.email)
  };

  const handleVerificationCodeChange = (evt) => {
    setVerificationCode(evt.target.value);
  };

  function onRegister(evt) {
    evt.preventDefault();
    if (userStatus !== 1) {
      setUserStatus(1);
      props.onRegister(values.email, values.password);
    } else {
      handleSubmitVerificationCode();
    }
  }

  let titleText = "";
  switch (true) {
    case userStatus === 1:
      titleText = "Подтвердите почту";
      break;
    case props.requestStatus === 200 && props.isExist && userStatus !== 1:
      titleText = "Войдите";
      break;
    case props.requestStatus === 200 && userStatus !== 1:
      titleText = "Зарегистрируйтесь";
      break;
    default:
      titleText = "Войдите или Зарегистрируйтесь";
      break;
  }

  const renderModalBody = 
    <>
    <label className="auth-modal__input-label" htmlFor="email">
    <input
      className="auth-modal__input"
      name="email"
      type="email"
      id="email"
      placeholder="E-mail"
      disabled={props.requestStatus === 200 && userStatus !== 1}
      pattern="[a-zA-Z0-9_.]+@[a-zA-Z0-9_]+\.[a-z]{2,}"
      required
      onChange={handleChange}
      value={values.email || ""}
    />
    <span className="auth-modal__input-error">{errors.email}</span>
  </label>
  {props.requestStatus === 200 &&  
        <label className="auth-modal__input-label" htmlFor="password">
          <div className="auth-modal__input-container">
            <input
                className="auth-modal__input"
                name="password"
                type={passwordVisible ? "text" : "password"}
                id="password"
                placeholder="Пароль"
                required
                minLength={6}
                onChange={handleChange}
                value={values.password || ""}
            />
            <span onClick={handlePasswordVisibilityToggle} className="auth-modal__icon">
              <img src={HideEye} alt="Toggle password visibility" />
            </span>
          </div>  
          <span className="auth-modal__input-error">{errors.password}</span>
          <span className="auth-modal__error">{props.isErrorMessage}</span>
            
        </label>}
  {props.requestStatus === 200 && !props.isExist &&
    <label className="auth-modal__input-label" htmlFor="passwordRepeat">
      <div className="auth-modal__input-container">
        <input
          className="auth-modal__input"
          name="passwordRepeat"
          type={passwordRepeatVisible ? "text" : "password"}
          id="passwordRepeat"
          placeholder="Повторите Пароль"
          required
          onChange={handleChange}
          value={values.passwordRepeat || ""}
        />
        <span onClick={handlePasswordRepeatVisibilityToggle} className="auth-modal__icon">
          <img src={HideEye} alt="Toggle repeat password visibility" />
        </span>
      </div>
      {passwordMismatchError !== "" && <span className="auth-modal__input-error">{passwordMismatchError}</span>}
    </label> }
  <button
    className="auth-modal__button"
    type="submit"
    aria-label="Войти"
    disabled={!isFormValid}
  >
    {props.isLoading ? "Сохранение..." : "Продолжить"}
  </button>
  </>

  const renderVerificationEmail = 
    <>
      {isLoading && <Loader/>}
      <p>Выслали код подтверждения на почту {values.email}</p>
      <input
        className="auth-modal__input"
        placeholder="Код подтверждения"
        onChange={handleVerificationCodeChange}
        value={verificationCode}
      />
      {isErrorMessage && <p style={{color: 'red'}}>{isErrorMessage}</p>}
      <button className="auth-modal__button">
        {props.isLoading ? "Сохранение..." : "Продолжить"}
      </button>
      <div className="auth-modal__resend-container">
        {canResend 
          ? <button className="auth-modal__resend-button" onClick={handleResendCode}>Отправить код</button>
          : <span>Отправить код повторно через {resendTimer} секунд</span>}
      </div>
    </> 

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (userStatus !== 1 && props.requestStatus === 200 && !props.isExist) {
      if (values.password !== values.passwordRepeat) {
        setPasswordMismatchError("Пароли не совпадают");
        return;
      } else {
        setPasswordMismatchError("");
      }
    }
    switch (true) {
      case userStatus === 1:
        handleSubmitVerificationCode(evt);
        break;
      case props.requestStatus === 200 && props.isExist && userStatus !== 1:
        onLogin(evt);
        break;
      case props.requestStatus === 200 && userStatus !== 1:
        onRegister(evt);
        break;
      default:
        handleContinueClick(evt);
        break;
    }
  };

  const modalOpened = props.isOpen ? "auth-modal_opened" : "";
  
  return(
    <section className={`auth-modal ${modalOpened}`} onClick={handleCloseModal}>
        <div className="auth-modal__container" onClick={evt => evt.stopPropagation()}>
            <button
              className="auth-modal__button-close"
              type="button"
              aria-label="Закрыть окно"
              onClick={handleCloseModal}
            ></button>
            <form className="auth-modal__form" name="auth-form" onSubmit={handleSubmit} noValidate autoComplete="on">
              <h2 className="auth-modal__title">
                {titleText}
              </h2>
              {userStatus !== 1 ? renderModalBody : renderVerificationEmail}
            </form>
        </div>
    </section>  
  )
}

export default AuthForm;