import React from "react";
import { useState } from "react";
import { Columns, Column, Button } from "react-bulma-components";
import CardPromoDetailed from "../CardPromoDetailed/CardPromoDetailed";

import './CardPromo.css'
import "../../components/Profile/Profile.css";
import InfoBlack from "../../images/info.svg"
import InfoWhite from "../../images/infoWhite.svg"
import Down from "../../images/down.svg"
import { promoAboutData, stageList, templateList } from "../../utils/constants"; 

// function CardPromo({id, textStart, textBold, textFor, textEnd, crossedPrices, actualPrices, ImageCards, onPromoIdChange, isChoosed}) {
function CardPromo({id, title, subtitle, textAbout1st, textAbout1stInfo, textAbout2nd, textAbout2stInfo, textAbout3rd, textEnd, price, template, stages, openIconBlack, openIconWhite, imageAi, sale, aboutPromo, onPromoIdChange, isChoosed, colorBlack}) {
  const [checkmark, setCheckMark] = useState(false);
  const [selectedPromoId, setSelectedPromoId] = useState(null);
  const [cardIdForSteps, setCardIdForSteps] = useState(null);
  const [cardIdForTemplate, setCardIdForTemplate] = useState(null);
  const [openInfo, setOpenInfo] = useState(false);
  const [openInfoTemplate, setOpenInfoTemplate] = useState(false);
  // const [isAboutPromo, setAboutPromo] = useState(false);
  const [isShowDetails, setShowDetails] = useState(false);
  const handleClickPromo = (event) => {
      onPromoIdChange(id);
      setCheckMark(!checkmark)
  };

  const handleClickBuy = (event) => {
    event.stopPropagation(); // Предотвращаем срабатывание handleClickPromo
    // setShowDetails(true);
    setSelectedPromoId(id);
  };

  const handleOpenInfo = () => {
    setCardIdForSteps(id)
    if (cardIdForSteps === id ) {
      setOpenInfo(!openInfo);
    }
  };

  const handleOpenInfotemplate = () => {
    setCardIdForTemplate(id)
    if (cardIdForTemplate === id ) {
      setOpenInfoTemplate(!openInfoTemplate);
    }
  };

  const handleOverlay = (e) => {
    if (e.target === e.currentTarget) {
      handleOpenInfo();
    }
    handleOpenInfo();
  };

  return (
    <>
    <div className={`card-promo__container ${((openInfo || openInfoTemplate) &&  (cardIdForSteps || cardIdForTemplate) === id)?  "card-promo__container_large" : ""} ${colorBlack? "card-promo__container_active" : ""}`} onClick={handleClickPromo} id={id}>
      {aboutPromo && 
      <div className="card-promo__about-container">
        <p className="card-promo__about-text">Больше информации о тарифе тут</p>
        <img className="card-promo__about-icon" src={Down} alt="стрелка"/>
      </div>  
      }
      {sale === true && <div className="card-promo__sale">Выгода 30%</div>}
      <div className="card-promo">
        <div className={`card-promo__main ${colorBlack? "card-promo__main_active" : ""}`}>
          <div className="card-promo__main-container">
            <div className="card-promo__title-container">
              <h2 className={`card-promo__title ${colorBlack? "card-promo__title_active" : ""}`}>{title}</h2>
              <img className="card-promo__image-ai" src={imageAi} />
            </div>
            <img onClick={handleClickBuy} className="card-promo__info" src={colorBlack? InfoWhite : InfoBlack} alt="Искусственный интелект"/>
          </div>
          <p className={`card-promo__subtitle ${colorBlack? "card-promo__subtitle_active" : ""}`}>{subtitle}</p>
        </div>
        <ul className="card-promo__list">
          <li className={`card-promo__item ${colorBlack? "card-promo__item_active" : ""}`}>
            {textAbout1st}
            <p className={`card-promo__item-info ${colorBlack? "card-promo__item-info_active": ""}`}>{textAbout1stInfo}</p>
            <div className="card-promo__dropdown">
              <p className={`card-promo__dropdown-button ${colorBlack? "card-promo__dropdown-button_card_middle" : "card-promo__dropdown-button_card_min"}`} onClick={handleOpenInfotemplate}>
                {template}
                {template && <img src={colorBlack? openIconBlack : openIconWhite} className={`card-promo__dropdown-icon_type_up ${openInfo? "card-promo__dropdown-icon_type_down" : ""}`}/>}
              </p>
              {openInfoTemplate && cardIdForTemplate === id &&
                <ol 
                  className={`card-promo__dropdown-list ${colorBlack? 'card-promo__dropdown-list_card_middle' : "card-promo__dropdown-list_card_min"}`}
                  aria-labelledby='card-promo__dropdown-list'
                  onClick={(e) => {handleOverlay(e)}}
                > {templateList.map((item, i) => {
                    return (
                      // <li className='card-promo__dropdown-item'key={i}>
                      <li className={`card-promo__dropdown-item ${colorBlack? "card-promo__dropdown-item_color_white" : "card-promo__dropdown-item_color_black)"}`} key={i}>
                        {item.title}
                        <p className="card-promo__item-info">{item.subtitle}</p>
                      </li>
                    );
                  })}
                </ol>}
            </div>
          </li>
          <li className={`card-promo__item ${colorBlack? "card-promo__item_active" : ""}`}>
            {textAbout2nd}
            <p className={`card-promo__item-info ${colorBlack? "card-promo__item-info_active": ""}`}>{textAbout2stInfo}</p>
          </li>
          <li className={`card-promo__item ${colorBlack? "card-promo__item_active" : ""}`}>{textAbout3rd}</li>
        </ul>
        <div className="card-promo__dropdown">
          <p className={`card-promo__dropdown-button ${colorBlack? "card-promo__dropdown-button_card_middle" : "card-promo__dropdown-button_card_min"}`} onClick={handleOpenInfo}>
            {stages}
            {stages && <img src={colorBlack? openIconWhite : openIconBlack} className={`card-promo__dropdown-icon_type_up ${openInfo? "card-promo__dropdown-icon_type_down" : ""}`}/>}
            {/* {stages !== "" && <img src={colorBlack? openIconWhite : openIconBlack} className="card-promo__dropdown-icon"/>} */}
          </p>
          {openInfo && cardIdForSteps === id &&
            <ol 
              // className={`card-promo__dropdown-list ${openInfo && 'card-promo__dropdown-list_is-open'}`}
              className={`card-promo__dropdown-list ${colorBlack? 'card-promo__dropdown-list_card_middle' : "card-promo__dropdown-list_card_min"}`}
              aria-labelledby='card-promo__dropdown-list'
              onClick={(e) => {handleOverlay(e)}}
            > {stageList.map((item, i) => {
                return (
                  <li className={`card-promo__dropdown-item ${colorBlack? "card-promo__dropdown-item_color_white" : "card-promo__dropdown-item_color_black)"}`} key={i}>
                    {item.title}
                  </li>
                );
              })}
            </ol>}
        </div>
        {/* <div className={`card-promo__end ${colorBlack? "card-promo__end_active" : ""}`}>{textEnd}</div> */}
      </div>
      <div className="card-promo__end-container">
        <div className={`card-promo__end ${colorBlack? "card-promo__end_active" : ""}`}>{textEnd}</div>
        <div className="card-promo__end-buy">
          <div className={`card-promo__price ${colorBlack? "card-promo__price_active" : ""}`}>{price}&#8381;</div>
          <button onClick={handleClickBuy} className={`card-promo__button ${colorBlack? "card-promo__button_active" : ""}`}>Купить</button>
        </div>
      </div>
      {/* не удалять!!!! */}
      {/* <div className={`card-promo__option ${colorBlack? "card-promo__option_active" : ""}`}></div> */}
    </div>
    {selectedPromoId === id && (
        <CardPromoDetailed isShowDetails={true} setShowDetails={() => setSelectedPromoId(null)} info={promoAboutData.find(promo => promo.id === id)} />
      )}
    </>
  );
}

export default CardPromo;