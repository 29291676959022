import React, { useState, useEffect } from 'react';
import './CardIdeaList.css';
import CardIdea from '../CardIdea/CardIdea';
import circul from '../../images/circul.svg';
import * as mainApi from "../../utils/MainApi";
import {cardIdeaData, SCREEN_SIZE} from '../../utils/constants';
import { useLocation } from 'react-router-dom';

function CardIdeaList(props) {
  const [shownNewIdea, setShowNewIdea] = useState(false);
  const [showCards, setShowCards] = useState(0);
  const [cardIdeaList, setCardIdeaList] = useState([]);
  const [bestIdeaList, setBestIdeaList] = useState([]);
  const [isBlur, setBlur] = useState(true);
  const location = useLocation();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const currentPath = location.pathname;

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      mainApi.getCards(token)
        .then((cards) => {
          setCardIdeaList(cards.slice().reverse())
          setBestIdeaList(cards.sort((a, b) => b.likes - a.likes).slice(0, 10))
        })
        .catch((err) => console.log(err))
    }
  }, [])

  useEffect(() => {
    if (screenWidth >= SCREEN_SIZE.DESKTOP) {
      setShowCards(4)
    } 
    else {
      setShowCards(2)
    }
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [screenWidth, setScreenWidth])

  const bestIdeas = 
    bestIdeaList.slice(0, showCards).map((data, index) => (
      <CardIdea
        key={index}
        title={data.title}
        likes={data.likes}
        dislikes={data.dislikes}
        chance={data.chance}
      />
    ))
  const newIdeas = 
    cardIdeaList.slice(0, showCards).map((data, index) => (
      <CardIdea
        key={index}
        title={data.title}
        likes={data.likes}
        dislikes={data.dislikes}
        chance={data.chance}
      />
    ))

  const ideasBlur = 
    cardIdeaData.slice(0, showCards).map((data, index) => (
      <CardIdea
        key={index}
        title={data.title}
        likes={data.likes}
        dislikes={data.dislikes}
        chance={data.chance}
      />
    ))


  const ideas = !props.isLoggedIn && currentPath ==='/' 
    ?
    ideasBlur
    :
    shownNewIdea ? newIdeas : bestIdeas
    // <>
    // {shownNewIdea ? newIdeas : bestIdeas}
  //   <div className="card-idea">
  //     <p className="card-idea__title">Идея</p>
  //     <div className="card-idea__container">
  //       <p className="card-idea__chance">вероятность успеха: <span className="card-idea__percent">100%</span></p>
  //     </div>
  //   </div>
  //   <div className="card-idea">
  //     <p className="card-idea__title">Идея</p>
  //     <div className="card-idea__container">
  //       <p className="card-idea__chance">вероятность успеха: <span className="card-idea__percent">100%</span></p>
  //     </div>
  //   </div>
  //   <div className="card-idea">
  //     <p className="card-idea__title">Идея</p>
  //     <div className="card-idea__container">
  //       <p className="card-idea__chance">вероятность успеха: <span className="card-idea__percent">100%</span></p>
  //     </div>
  //   </div>
  //   <div className="card-idea">
  //     <p className="card-idea__title">Идея</p>
  //     <div className="card-idea__container">
  //       <p className="card-idea__chance">вероятность успеха: <span className="card-idea__percent">100%</span></p>
  //     </div>
  //   </div>
  // </>

  const renderButtonShowMore = 
    <button
      className="card-idea__list-button"
      onClick={() => setShowCards(prevValue => prevValue + 2)}
      > 
      Смотреть еще
      <img src={circul} className="card-idea__list-img" alt="смотреть еще" />
    </button>
  return (
    <> 
      <section className="card-idea__list-container"> 
          <div className="card-idea__nav"> 
            <p className="card-idea__type" style={shownNewIdea ? {color: 'rgba(47, 47, 55, 0.35)'} : {}} onClick={() => {setShowNewIdea(false); setShowCards(showCards)}}>Лучшие идеи</p> 
            <p className="card-idea__type" style={!shownNewIdea ? {color: 'rgba(47, 47, 55, 0.35)'} : {}} onClick={() => {setShowNewIdea(true); setShowCards(showCards)}}>Новые идеи</p> 
          </div> 
          <div className={`card-idea__list ${isBlur && currentPath === "/" ? 'card-idea__list-blur' : ''}`}> 
            {/* {shownNewIdea ? newIdeas : bestIdeas}  */}
            {/* {cardIdeaList.length === 0 && 'Здесь пока ничего нет...'} */}
            {ideas}   
          </div>     
      </section> 
        {currentPath !== "/" && showCards < cardIdeaList.length && renderButtonShowMore} 

        {currentPath === "/" &&
        // cardIdeaList.length !== 0 && <button className="card-idea__button" onClick={() => setBlur(!isBlur)}>Открыть доступ</button> }
        cardIdeaData.length !== 0 && <button className="card-idea__button" onClick={() => setBlur(!isBlur)}>Открыть доступ</button> }
        
    </> 
  )
}

export default CardIdeaList;