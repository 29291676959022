import React from "react";
import './Team.css'

import person1 from "../../images/MS_person.png"
import facebook from "../../images/facebookBlack.svg"
import vk from "../../images/vk.svg";
import telegram from "../../images/telegram.svg";
import Sara from "../../images/Sara.svg";

function Team() {
  return(
    <section className="team">
        <div className="team__experience">
            <div className="team__container">
                <h2 className="team__title">Опыт</h2>
                    {/* <p className="team__description">Необходимо выделить квалификацию и опыт нашей команды. Указать, как наш уникальный опыт и знания помогают клиентам достигать желаемых результатов. </p> */}
                    <div className="team__person">
                        {/* <img className="team__icon" src={Sara} alt="указатель"/> */}
                        <img className="team__image" src={person1} alt="Михаил Саранчев" />
                        {/* <div> */}
                        <p className="team__person-about">Свыше 15 лет опыта в стратегическом консалтинге, 
                            включая разработку бизнес-планов, проведение рыночного 
                            анализа и финансовое моделирование. 
                            Его знания помогут выстроить четкую стратегию и продумать каждый шаг на пути к успеху.</p>
                    </div>
            </div>
            <div className="team__container-info">
                <p className="team__info-title">Консультирование в топовых компаниях</p>
                <p className="team__info-description">
                    Более 30 проектов по запуску новых продуктов в ИТ, 
                    телекоме, финансах, машиностроении, химии и FMCG были успешно реализованы с его консультационной поддержкой. 
                    Он понимает все тонкости различных отраслей и может предвидеть потенциальные вызовы.
                </p>
                <p>Принимал участие в запуске бизнесов с общей выручкой более 44 млрд рублей. Он знает, 
                    как превратить идею в прибыльное предприятие
                </p>
            </div>
            <div className="team__container-info">
                <p className="team__info-title">Образование</p>
                <p className="team__info-description">
                    Выпускник МГУ и обладатель MBA Сколково, наш эксперт сочетает в себе крепкую теоретическую базу 
                    и практические навыки управления бизнесом
                </p>
            </div>
            <div className="team__container-info">
                <p className="team__info-title">Признание и участие в конкурсах</p>
                <p className="team__info-description">
                    Победитель конкурса грантов Сколково и конкурса управленцев, а также член жюри кейс чемпионатов. 
                    Это подтверждает его высокий профессионализм и признание в деловом сообществе
                </p>
            </div>
        </div>
        <div className="team__contacts">
            <p className="team__contacts-title">Контакты</p>
            <ul className="team__contacts-list">
                <li>
                    <p className="team__contacts-subtitle">Телефон</p>
                    <p className="team__contacts-data">+7 (977) 142-12-24</p>
                </li>
                <li>
                    <p className="team__contacts-subtitle">Почта</p>
                    <p className="team__contacts-data">inbox@bsculptor.ru</p>
                </li>
                {/* <li>
                    <p className="team__contacts-subtitle">Социальные сети</p>
                    <ul className="team__social-links">
                        <li>
                            <img className="team__social-link" src={facebook} alt="facebook"></img>
                        </li>
                        <li>
                            <img className="team__social-link" src={vk} alt="vk"></img>
                        </li>
                        <li>
                            <img className="team__social-link" src={telegram} alt="telegram"></img>
                        </li>
                    </ul>
                </li> */}
            </ul>
        </div>
        {/* <div className="team__container-info">
            <p className="team__info-title">Консультирование в топовых компаниях</p>
            <p className="team__info-description">
                Более 30 проектов по запуску новых продуктов в ИТ, 
                телекоме, финансах, машиностроении, химии и FMCG были успешно реализованы с его консультационной поддержкой. 
                Он понимает все тонкости различных отраслей и может предвидеть потенциальные вызовы.
            </p>
            <p>Принимал участие в запуске бизнесов с общей выручкой более 44 млрд рублей. Он знает, 
                как превратить идею в прибыльное предприятие
            </p>
        </div>
        <div className="team__container-info">
            <p className="team__info-title">Образование</p>
            <p className="team__info-description">
                Выпускник МГУ и обладатель MBA Сколково, наш эксперт сочетает в себе крепкую теоретическую базу 
                и практические навыки управления бизнесом
            </p>
        </div>
        <div className="team__container-info">
            <p className="team__info-title">Признание и участие в конкурсах</p>
            <p className="team__info-description">
                Победитель конкурса грантов Сколково и конкурса управленцев, а также член жюри кейс чемпионатов. 
                Это подтверждает его высокий профессионализм и признание в деловом сообществе
            </p>
        </div> */}
        
     </section>
  )
}

export default Team;