import React, { useState, useEffect }  from 'react';
import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';

import "bulma/css/bulma.min.css";
import "./App.css";

import Header from "../Header/Header";
import RenderFooter from "../Footer/Footer";
import RenderProgress from "../Progress/Progress";
import Promo from "../Promo/Promo";
import Main from "../Main/Main";
import Ideas from "../Ideas/Ideas";
import Loader from "../Loader/Loader";
import Profile from "../Profile/Profile";
import { IdeaTextContext } from "../../context/IdeaTextContext";
import { CurrentUserContext } from "../../context/CurrentUserContext";
import DataAdmin from '../DataAdmin/DataAdmin';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute'
import * as authApi from "../../utils/AuthApi";
import { 
  STATUS_CODE_400, 
  STATUS_CODE_401, 
  STATUS_CODE_409, 
  ERROR_MESSAGE
} from '../../utils/constants';

function App() {
  const [ideaValue, setIdeaValue] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState("");
  const [successRegister, setSuccessRegister] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [isExist, setExist] = useState(false);
  const [userStatus, setUserStatus] = useState(-1);
  const [requestStatus, setRequestStatus] = useState('');
  const [isModalLogin, setIsModalLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); 

  function handleOpenModal(showModal) {
    setIsModalLogin(showModal);
  };

  function handleCloseModal(closeModal) {
    setExist(false)
    setUserStatus(-1)
    setRequestStatus(false)
    setIsModalLogin(closeModal);
  };

  useEffect(() => {
    if (!isModalLogin) return;

    function handleCloseByEsc(evt) {
      if (evt.key === 'Escape') {
        setIsModalLogin(false)
      }
    }
    document.addEventListener('keydown', handleCloseByEsc);
    return() => {
      document.removeEventListener('keydown', handleCloseByEsc)
    }
  }, [isModalLogin]);


  function handleIdeaTitle(text) {
    setIdeaValue(text)
  }

  useEffect(() => {
    checkToken()
  }, [])

  function checkToken() {
    const token = localStorage.getItem('token');
    if (token) {
      authApi.checkToken(token)
        .then((res) => {
          setCurrentUser(res)
          setIsLoggedIn(true)

        })
        .catch((err) => {
          setIsLoggedIn(false)
          navigate("/", { replace: true });
        })
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (isLoggedIn) {
      authApi.getCurrentUser(token)
        .then((dataUser) => {
          setCurrentUser(dataUser)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [isLoggedIn])

  function handleContinueClick(email) {
    setIsLoading(true)
    authApi.checkUser(email)
      .then((res) => {
        setExist(res.data.exists)
        setUserStatus(res.data.userStatus)
        setRequestStatus(res.status)
      })
      .catch((err) => {
        console.error(`Ошибка: ${err}`)
      })
      .finally(() => {
        setIsLoading(false)
      })
  };

  function onRegister(email, password) {
    authApi.register(email, password)
      .then((res) => {
        // setSuccessRegister(true)
        // setIsModalLogin(false)
      })
      .catch((err) => {
        if (err === STATUS_CODE_409) {
          setIsErrorMessage(ERROR_MESSAGE.CONFLICT_USER_DATA)
        }
        if (err === STATUS_CODE_400) {
          setIsErrorMessage(ERROR_MESSAGE.USER_CREDENTIALS)
        }
        setSuccessRegister(false)
        console.error(`Ошибка регистрации: ${err}`)
      })
      .finally(() => {
        setTimeout(() => setIsErrorMessage("tttt"), 2000)
      })
  }


  function onLogin(email, password) {
    setIsLoading(true)
    authApi.login(email, password)
      .then((res) => {
        localStorage.setItem("token", res.token);
        setIsLoggedIn(true)
        setCurrentUser(res)
        navigate("/", { replace: true });
      })
      .catch((err) => {
        console.log(err)
        if (err === STATUS_CODE_400) {
          setIsErrorMessage(ERROR_MESSAGE.USER_AUTH)
        }
        if (err === STATUS_CODE_401) {
          setIsErrorMessage(ERROR_MESSAGE.USER_CREDENTIALS)
        }
      })
      .finally(() => {
        setIsLoading(false)
        setTimeout(() => setIsErrorMessage(""), 2000)
        window.location.reload()
      })
  }

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <IdeaTextContext.Provider value={ideaValue}>
        {isLoading && <Loader/>}
        <Routes>
          <Route
            path='/'
            element={
              <>
                <Header 
                  isLoggedIn={isLoggedIn}
                  onRegister={onRegister} 
                  handleContinueClick={handleContinueClick}
                  userStatus={userStatus} 
                  requestStatus={requestStatus} 
                  isExist={isExist}
                  onLogin={onLogin}
                  openModal={handleOpenModal}
                  closeModal={handleCloseModal}
                  isOpen={isModalLogin}
                  onClose={() => setIsModalLogin(false)}
                  onLogout={() => setIsLoggedIn(false)}
                  />
                <Main testText={handleIdeaTitle} isLoggedIn={isLoggedIn}/>
                <RenderFooter isLoggedIn={isLoggedIn}/>
              </>
            }
          ></Route>
          {/* <Route
            path="/sign-in"
            element={
              isLoggedIn ? (
                <Navigate to="/"/>
              ) : (
                <Login
                  onLogin={onLogin}
                  errorMessage={isErrorMessage}
                  setIsErrorMessage={setIsErrorMessage}
                />
              )
            }
          ></Route> */}
          <Route
            path='/profile'
            element={
              // <ProtectedRoute>
                <>
                  <Header isLoggedIn={isLoggedIn}/>
                  <Profile isLoggedIn={isLoggedIn}/>
                  <RenderFooter isLoggedIn={isLoggedIn}/>
                </>
              // </ProtectedRoute>
            }
          ></Route>
          <Route
            path='/other-ideas'
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <>
                    <Header isLoggedIn={isLoggedIn}/>
                    <Ideas />
                    <RenderFooter isLoggedIn={isLoggedIn}/>
                  </> 
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path='/progress-bar'
            element={
              <>
                {Header(isLoggedIn)}
                {/*{RenderProgress()}*/}
                {RenderFooter(isLoggedIn)}
              </>
            }
          ></Route>
          <Route
            path='/promo'
            element={
              <>
                <Header 
                  isLoggedIn={isLoggedIn}
                  onRegister={onRegister} 
                  handleContinueClick={handleContinueClick}
                  userStatus={userStatus} 
                  requestStatus={requestStatus} 
                  isExist={isExist}
                  onLogin={onLogin}
                  openModal={handleOpenModal}
                  closeModal={handleCloseModal}
                  isOpen={isModalLogin}
                  onClose={() => setIsModalLogin(false)}
                  onLogout={() => setIsLoggedIn(false)}
                />
                <Promo />
                <RenderFooter isLoggedIn={isLoggedIn}/>
              </>
            }
          ></Route>
          {/* <Route
            path='/admin2024bsculptor'
            element={
              <>
                <DataAdmin />
              </>
            }
          ></Route> */}
          <Route
            path='/admin2024bsculptor'
            element={
              // <ProtectedRoute isLoggedIn={isLoggedIn}>
              <ProtectedRoute >
                <DataAdmin />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="*"
            element={isLoggedIn && <Navigate to="/" replace></Navigate>} 
          ></Route>
        </Routes>
      </IdeaTextContext.Provider>
    </CurrentUserContext.Provider>  
  );
}

export default App;