import React, { useState, useEffect, useContext } from 'react';
import './Loader.css';
// import loading from "../../images/loader.gif";


const Loader = () => {
    return (
      <div className="load">
        {/* <img src={loading} alt="Loading..." /> */}
      </div>
    );
  };
  
  export default Loader;