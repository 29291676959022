// export const BASE_URL = "http://localhost:4000";
export const BASE_URL = "https://api.bsculptor.ru";

// регистрация пользователя
export const register = (email, password) => {
  return fetch(`${BASE_URL}/sign-up`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({email, password})
  })
  .then((res) => res.ok ? res.json() : Promise.reject(res.status));
}

// авторизация пользователя
export const login = (email, password) => {
  // return fetch(`${BASE_URL}/signin`, {
  return fetch(`${BASE_URL}/sign-in`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({email, password})
  })
  .then((res) => res.ok ? res.json() : Promise.reject(res.status));
}
// проверка существует ли такой пользователь в системе по имейлу
export const checkUser = (email) => {
  const url = new URL(`${BASE_URL}/user/${email}/checkUser`);
  return fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json'
    }
  })
  .then((res) => {
    return res.json().then(data => ({ status: res.status, data }));
  })
  .catch((err) => {
    throw err;
  });
}
// проверка кода верификации
export const checkVerificationCode = (email, code) => {
  const url = new URL(`${BASE_URL}/user/checkVerificationCode`);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, code }) 
  })
  .then((res) => {
    return res.json().then(data => ({ status: res.status, data }));
  })
  .catch((err) => {
    throw err;
  });
}
// получение данных текущего пользователя по имейлу
export const getCurrentUser = (token) => {
  const url = new URL(`${BASE_URL}/user/me`);
  return fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
    }
  })
  .then((res) => {
    // return res.json().then(data => ({ status: res.status, data }));
    return res.json();
  })
  .catch((err) => {
    throw err;
  });
}


// обновление данных пользователя
export const updateCurrentUser = (data, token) => {
  const url = new URL(`${BASE_URL}/user/updateCurrentUser`);
  return fetch(url, {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    return res.json().then(data => ({ status: res.status, data }));
  })
  .catch((err) => {
    throw err;
  });
};

// проверка токена
export const checkToken = (token) => {
  return fetch(`${BASE_URL}/user/me`, {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
  .then((res) => res.ok ? res.json() : Promise.reject(res.status));
}

export const resendVerificationCode = (email) => {
  return fetch(`${BASE_URL}/user/resendVerificationCode`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      'Accept': 'application/json'
    },
    body: JSON.stringify({ email })
  })
  .then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return Promise.reject(`Ошибка: ${res.status}`);
    }
  });
};