import React from 'react'
import './Footer.css';
import { Link } from 'react-router-dom';
import logoMain from "../../images/bsculptor_logo_main.svg";
// import facebook from "../../images/facebookBlack.svg"
// import vk from "../../images/vk.svg";
// import telegram from "../../images/telegram.svg";
import { Footer, Columns } from "react-bulma-components";
import { PROFILE_INFO, HEADER_INFO } from "../../utils/constants";

function RenderFooter(props) {
    return (
    <>
        <Footer>
            <Columns mb='211px'>
                <Columns.Column>
                    <Link to="/">
                        <img className="footer__logo" src={logoMain} alt='Логотип bsculptor'></img>
                    </Link>
                </Columns.Column>
                <Columns.Column className='footer__section_nav-link'>
                    <p>
                        <Link to="/" className="footer__nav-link">{HEADER_INFO.checkYourIdea}</Link>
                    </p>
                    {props.isLoggedIn &&
                    <p>
                        <Link to="/other-ideas" className="footer__nav-link">{HEADER_INFO.evaluateOtherIdeas}</Link>
                    </p>
                    }
                    <p>
                        <Link to="/promo" className="footer__nav-link">{PROFILE_INFO.services}</Link>
                    </p>
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column>
                    <p className="footer__copyright">&copy; 2024 bsculptor</p>
                </Columns.Column>
                <Columns.Column className='footer__section_nav-link'>
                </Columns.Column>
            </Columns>
        </Footer>
    </>
  )
}

export default RenderFooter;
